export const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_API_URL = `${BASE_API_URL}api/v1/login/`;

export const BUY_UPDATE_API_URL = `${BASE_API_URL}api/v1/bid-update/`;

export const BUY_DELETE_API_URL = `${BASE_API_URL}api/v1/bid-delete/`;

export const PRODUCT_API_URL = `${BASE_API_URL}api/v1/products/`;

export const EXPORT_API_URL = `${BASE_API_URL}media/csv/products.csv`;

export const BULK_DELETE_API_URL = `${BASE_API_URL}api/v1/bulk-delete-bid/`;

export const PENDING_API_URL = `${BASE_API_URL}api/v1/pending-history/`;

export const STATISTICS_API_URL = `${BASE_API_URL}api/v1/statistics/`;

export const HIDE_API_URL = `${BASE_API_URL}api/v1/product-to-hide/`;

export const BID_HISTORY_API_URL = `${BASE_API_URL}api/v1/bid-history/`;

export const PRODUCT_SYNC_API_URL = `${BASE_API_URL}api/v1/product-sync/`;

export const PROXY_API_URL = `${BASE_API_URL}api/v1/proxy-urls/`;

export const SCHEDULER_API_URL = `${BASE_API_URL}api/v1/product-scrappy/`;

export const GOAT_API_URL = `${BASE_API_URL}api/v1/goat-products/`;

export const BULK_UPDATE_RESULTS_API_URL = `${BASE_API_URL}api/v1/bulk-updated-bid-history/`;

export const GOAT_STOCKX_API_URL = `${BASE_API_URL}api/v1/product-mapping/`;

export const LAST_SYNC_API_URL = `${BASE_API_URL}api/v1/last-sync/`;

export const MAX_BID_HISTORY_API_URL = `${BASE_API_URL}api/v1/max-bid-history/`;

export const RUN_ID_API_URL = `${BASE_API_URL}api/v1/bulk-update-run-id-list/`;

export const BULK_BID_UPDATE_HISTORY_API_URL = `${BASE_API_URL}api/v1/bulk-bid-update-history-list/`;

export const BULK_UPDATE_CANCEL_OR_REVERT = `${BASE_API_URL}api/v1/bulk-update-cancel-or-revert/`;

export const GLOBAL_UPDATE_API = `${BASE_API_URL}api/v1/global-update-history-list/`;

export const STOCKX_TOKEN_API = `${BASE_API_URL}api/v1/update-stockX-token/`;

export const BRAND_API_URL = `${BASE_API_URL}api/v1/stockx-brands/`;

export const STOCKX_BUY_NOW_API_URL = `${BASE_API_URL}api/v1/stock-x-buying-products/`;

export const STOCKX_MODELING_SETTING_API_URL = `${BASE_API_URL}api/v1/modeling-settings/`;

export const SYNC_STOCK_X_BUY_NOW_API_URL = `${BASE_API_URL}api/v1/sync-stock-x-buy-now/`;

export const PLATFORM_SETTING_API_URL = `${BASE_API_URL}api/v1/core-settings/`;


