import React, { useReducer, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';

import {
  GroupingState,
  CustomGrouping,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';

import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableInlineCellEditing,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableEditColumn,
  TableFilterRow
} from '@devexpress/dx-react-grid-bootstrap4';

import Cancel from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';

import 'react-datepicker/dist/react-datepicker.css';

import {
  PRODUCT_API_URL,
  BUY_UPDATE_API_URL,
  BUY_DELETE_API_URL,
  EXPORT_API_URL,
  BULK_DELETE_API_URL,
  BID_HISTORY_API_URL,
  PRODUCT_SYNC_API_URL,
  MAX_BID_HISTORY_API_URL
} from '../../configurations/configApi.jsx';
import GridItem from '../../components/Grid/GridItem';
import LoadingOverlay from '../../components/overlays/LoadingOverlay.jsx';
import Permission from '../../permission/Permission.jsx';
import SnackbarContent from '../../components/Snackbar/SnackbarContent.jsx';
import spinner from '../../assets/img/spinner.svg';
import exportImg from '../../assets/img/export.svg';

import { Alert } from 'react-bootstrap';

const URL = PRODUCT_API_URL;

let bidFields = false;

let diffShowing = false;

let filterList = [];

let filterAutoBidList = [];

let fakeList = [];

let statusEdited = false;

let retainErrorListValue = [];

let autoBidEdited = false;

let activeValue = false;

let inActiveValue = false;

let auto_active = false;

let auto_inactive = false;

let bulkMessage = [];

let filterFocus, rowFocus;

let noRetain = [];

let searchValueNew = '';

let setEnterKeyForNameEditor = false;

let setEnterKeyForPurchaseEditor = false;

let BidEditorFilterStatus = false;

let selectedDate = '';

let errors = [];

let errorResponseData = {};

let bidUpdateStatus = [];

let bulkUpdate = false;

let bulkUpdateArray = [];

let bulkUpdateFilterOperation = '-';

const getRowId = row => {
  return row.id;
};

const getChildGroups = groups =>
  groups.map(group => {
    return {
      key: [
        group.key,
        group.items[0]['image_url'],
        group.items[0]['url'],
        group.items[0]['parent_id'],
        group.items[0]['id'],
        group.items
      ],
      childRows: group.items
    };
  });

// function to show icons on filter fields
const FilterIcon = ({ type }) => {
  /* in and not-in filter is not an in-built filter function,
   so need to apply external filter icon */
  if (type === 'in') {
    return <span className="d-block oi oi-magnifying-glass" />;
  } else if (
    ['doesNotIn', 'dynamicStatusIsActive', 'emptyValue'].includes(type)
  ) {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  } else if (type === 'greaterThanOrEqualMaxBid') {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  } else if (type === 'dynamicDisabledAndGreaterThan') {
    return <span class="d-block oi oi-magnifying-glass cross" />;
  }

  return <TableFilterRow.Icon type={type} />;
};

const tableMessages = {
  noData: 'No Data Found'
};

// filter conditions to be shown in filter fields
const filterMessages = {
  in: 'In',
  doesNotIn: 'Not In',
  month: 'Month equals',
  greaterThanOrEqualMaxBid: 'Greater than max bid amount',
  lessThanOrEqualMaxBid: 'Less than max bid amount',
  dynamicStatusIsActive: 'Dynamic bid enabled Or disabled',
  dynamicDisabledAndGreaterThan: 'Dynamic bid disabled',
  emptyValue: 'No Value',
  noSaleInPast: 'No sales in past by day',
};

// function to show auto-sync date editor
const AutoSyncEditor = ({ value, onValueChange, row }) => {
  statusEdited = false;
  autoBidEdited = false;

  const handleKey = e => {
    if (e.which === 13) {
      setEnterKeyForPurchaseEditor = true;
      onValueChange(value);
    } else {
      e.preventDefault();
      return false;
    }
  };

  const handleChange = event => {
    filterFocus = '';
    selectedDate = event;
    let month = event && event.getMonth() + 1;
    const formatDate = event
      ? event.getFullYear() + '-' + month + '-' + event.getDate()
      : '';
    setEnterKeyForPurchaseEditor = true;
    onValueChange(formatDate);
  };

  return row ? (
    <p>{value}</p>
  ) : (
    <DatePicker
      className="pick-calender"
      dateFormat="MM/dd/yyyy"
      placeholderText="mm/dd/yyyy"
      selected={selectedDate}
      isClearable
      onChange={date => handleChange(date)}
      onKeyDown={event => handleKey(event)}
    />
  );
};

const NameEditor = ({ value, onValueChange, row, column }) => {
  statusEdited = false;
  autoBidEdited = false;
  const handleKeyDown = event => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 'v') {
      setEnterKeyForNameEditor = true;
    } else if (event.ctrlKey && charCode === 'x') {
      setEnterKeyForNameEditor = true;
    }
  };

  const handleKey = event => {
    if (event.which === 13) {
      setEnterKeyForNameEditor = true;

      onValueChange(value);
    }
  };

  const handleChange = event => {
    statusEdited = false;
    autoBidEdited = false;
    filterFocus = '';
    rowFocus = '';
    if (event.target.value === '') {
      setEnterKeyForNameEditor = true;
      onValueChange(event.target.value);
    }
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  return row ? (
    <p>{row[column.name]}</p>
  ) : (
    <input
      className="form-control text-right"
      type="text"
      tabIndex="0"
      placeholder="Filter..."
      value={value === undefined ? '' : value}
      min={0}
      onChange={handleChange}
      onKeyPress={e => handleKey(e)}
      onKeyDown={e => handleKeyDown(e)}
    />
  );
};

const showBlukUpdate = (value, column, operation) => {
  let data = {
    value: value,
    title: column,
    operation: operation
  };

  let newbulkUpdateArray = bulkUpdateArray;
  for (let i = 0; i < newbulkUpdateArray.length; i++) {
    if (newbulkUpdateArray[i].title === column) {
      newbulkUpdateArray.splice(newbulkUpdateArray - 1, 1);
    }
  }
  newbulkUpdateArray = [...bulkUpdateArray, data];
  bulkUpdateArray = newbulkUpdateArray;
};

const initialState = {
  data: [],
  grouping: [{ columnName: 'title' }],
  expandedGroups: [],
  tempGrouping: null,
  tempExpandedGroups: null,
  loading: false,
  totalCount: 0,
  errorMessage: '',
  successMessage: ''
};

// function to show the status fields editor on each row and on filter fields
const StatusEditor = ({ onValueChange, row }) => {
  const handleChange = event => {
    statusEdited = true;
    onValueChange(event.target.name);
    if (event.target.value === 'Active' && event.target.checked) {
      activeValue = true;
    }
    if (event.target.value === 'Active' && !event.target.checked) {
      activeValue = false;
    }
    if (event.target.value === 'Inactive' && event.target.checked) {
      inActiveValue = true;
    }
    if (event.target.value === 'Inactive' && !event.target.checked) {
      inActiveValue = false;
    }
  };

  return row ? (
    <img
      src={window.location.origin + row['status_image']}
      alt=""
      title={row['status'] ? 'Active' : 'Inactive'}
    />
  ) : (
    <div className="cu-checkbox">
      <input
        type="checkbox"
        style={{ width: '30px', height: '40px' }}
        id="active"
        name="active"
        value="Active"
        checked={activeValue}
        onChange={handleChange}
        title="Active"
      />
      <label for="active" title="Active">
        Active
      </label>
      <input
        type="checkbox"
        style={{ width: '30px', height: '40px' }}
        id="inactive"
        name="inactive"
        checked={inActiveValue}
        value="Inactive"
        onChange={handleChange}
        title="Inactive"
      />
      <label for="inactive" title="Inactive">
        Inactive
      </label>
    </div>
  );
};

function reducer(state, { type, payload }) {
  const { grouping, expandedGroups, tempGrouping } = state;

  switch (type) {
    case 'CHANGE_GROUPING':
      return {
        ...state,
        loading: true,
        grouping: payload,
        tempGrouping:
          tempGrouping === null ? grouping : tempGrouping,
        tempExpandedGroups: expandedGroups
      };
    case 'SET_EXPANDED_GROUPS':
      return {
        ...state,
        expandedGroups: payload
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: payload.data,
        totalCount: payload.totalCount,
        tempGrouping: null,
        tempExpandedGroups: null,
        loading: false
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false
      };
    case 'FETCH_COMMIT_CHANGES':
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case 'FETCH_CHANGE_SUCCESS':
      return {
        ...state,
        loading: false,
        successMessage: payload.message
      };
    case 'FETCH_UPDATE_ERROR':
      return {
        ...state,
        errorMessage: payload.message,
        loading: false
      };
    case 'LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [defaultHiddenColumnNames] = useState([
    'id',
    'parent_id',
    'category',
    'color'
  ]);

  const [columns] = useState([
    { name: 'name', title: 'Item' },
    // { name: "chainId", title: "ChainId" },
    { name: 'title', title: 'Title' },
    { name: 'sale_last72_hours', title: 'Sale Last 72 Hours' },
    { name: 'brand', title: 'Brand' },
    { name: 'size', title: 'Size' },
    { name: 'bid_amount', title: 'Bid Price' },
    { name: 'max_bid_amount', title: 'Max Bid Amount' },
    { name: 'is_auto_bid_active', title: 'Auto Bid Status' },
    {
      name: 'is_modeling_auto_bid_active',
      title: 'Dynamic Auto Bid'
    },
    { name: 'lowest_ask', title: 'Lowest Ask' },
    { name: 'highest_bid', title: 'Highest Bid' },
    { name: 'last_sale', title: 'Last Sale' },
    { name: 'category', title: 'Category' },
    { name: 'color', title: 'Colour' },
    { name: 'status_image', title: 'Status' },
    { name: 'style_id', title: 'Style Id' },
    { name: 'number_of_bids', title: 'No: of Bids' },
    { name: 'number_Of_asks', title: 'No: of Asks' },
    {
      name: 'auto_bid_last_sync_modified',
      title: 'Auto Bid last Sync'
    },
    { name: 'updated_on', title: 'Updated On' },
    { name: 'department', title: 'Department' },
    { name: 'retail_price', title: 'Retail Price' },
    { name: 'release_year', title: 'Release Year' },
    { name: 'id', title: 'Id' },
    { name: 'parent_id', title: 'Parent Id' }
  ]); //column to specify in table

  const [sorting, setSorting] = useState([]); // sorting param
  const [lastQuery, setLastQuery] = useState(); // used to check and invoke loadData()
  const [pageSize] = useState(30); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0); // to get current page
  const [searchValue, setSearchValue] = useState(''); // to get search params
  const [flag, setFlag] = useState([]);
  const [imageColumns] = useState(['title']); // to display the custom field value with image
  const [statusColumns] = useState(['status_image']); // to display the custom field value with image
  const [sweetAlert, setSweetAlert] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [show, setShow] = useState(false); // to show alert message for delete if 404 or 500 error.

  const [filters, setFilters] = useState([]);
  const [enterKeyValue, setEnterKeyValue] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [bulkData, setBulkData] = useState({ page_type: 'current' }); // to store the bulk update datas

  const [groupingStateColumnExtensions] = useState([
    { columnName: 'name', groupingEnabled: false },
    { columnName: 'bid_amount', groupingEnabled: false },
    { columnName: 'chainId', groupingEnabled: false },
    { columnName: 'brand', groupingEnabled: false },
    { columnName: 'lowest_ask', groupingEnabled: false },
    { columnName: 'highest_bid', groupingEnabled: false },
    { columnName: 'last_sale', groupingEnabled: false },
    { columnName: 'category', groupingEnabled: false },
    { columnName: 'color', groupingEnabled: false },
    { columnName: 'status_image', groupingEnabled: false },
    { columnName: 'size', groupingEnabled: false },
    { columnName: 'url', groupingEnabled: false },
    { columnName: 'image_url', groupingEnabled: false },
    { columnName: 'title', groupingEnabled: false },
    { columnName: 'style_id', groupingEnabled: false },
    { columnName: 'number_of_bids', groupingEnabled: false },
    { columnName: 'number_Of_asks', groupingEnabled: false },
    { columnName: 'max_bid_amount', groupingEnabled: false },
    { columnName: 'is_auto_bid_active', groupingEnabled: false },
    { columnName: 'updated_on', groupingEnabled: false },
    { columnName: 'department', groupingEnabled: false },
    { columnName: 'id', groupingEnabled: false },
    { columnName: 'parent_id', groupingEnabled: false },
    {
      columnName: 'auto_bid_last_sync_modified',
      groupingEnabled: false
    },
    { columnName: 'release_year', groupingEnabled: false },
    { columnName: 'retail_price', groupingEnabled: false },
    {
      columnName: 'is_modeling_auto_bid_active',
      groupingEnabled: false
    },
    { columnName: 'sale_last72_hours', groupingEnabled: false }
  ]); // to disable grouping with this boolean field
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'image_url', sortingEnabled: false },
    { columnName: 'status_image', sortingEnabled: false },
    { columnName: 'url', sortingEnabled: false },
    { columnName: 'id', sortingEnabled: false },
    { columnName: 'parent_id', sortingEnabled: false }
  ]);

  const [filteringStateColumnExtensions] = useState([
    { columnName: 'status_image', filteringEnabled: false },
    { columnName: 'is_auto_bid_active', filteringEnabled: false },
    { columnName: 'updated_on', filteringEnabled: false },
    {
      columnName: 'is_modeling_auto_bid_active',
      predicate: (value, filter, row) => {
        if (
          filter &&
          ['greaterThanOrEqualMaxBid', 'lessThanOrEqualMaxBid'].includes(filter.operation)
        ) {
          return true;
        }
        return IntegratedFiltering.defaultPredicate(
          value,
          filter,
          row
        );
      }
    }
  ]);

  const editColumnMessages = {
    editCommand: <Edit title="Edit" />,
    deleteCommand: <Delete title="Delete" />,
    commitCommand: <Save title="Save" />,
    cancelCommand: <Cancel title="Cancel" />
  };
  const [bidColumns] = useState(['bid_amount']);
  const [autoSyncColumns] = useState([
    'auto_bid_last_sync_modified'
  ]);
  const [maxBidAmtColumns] = useState(['max_bid_amount']);
  const [lowestAskColumns] = useState(['lowest_ask']);
  const [highestBidColumns] = useState(['highest_bid']);
  const [numberOfBidsColumns] = useState(['number_of_bids']);
  const [numberOfAsksColumns] = useState(['number_Of_asks']);
  const [nameColumns] = useState(['name']);
  const [salesLast72HoursColumns] = useState(['sale_last72_hours']);
  const [brandColumns] = useState(['brand']);
  const [updatedOnColumns] = useState(['updated_on']);
  const [idColumns] = useState(['id']);
  const [categoryColumns] = useState(['category']);
  const [colorColumns] = useState(['color']);
  const [sizeColumns] = useState(['size']);
  const [styleColumns] = useState(['style_id']);
  const [autoBidActiveColumns] = useState(['is_auto_bid_active']);
  const [modelingAutoBidActiveColumns] = useState([
    'is_modeling_auto_bid_active'
  ]);
  const [releaseYearColumns] = useState(['release_year']);
  const [retailPriceColumns] = useState(['retail_price']);
  const [departmentColumns] = useState(['department']);
  const [parentIdColumns] = useState(['parent_id']);
  const [lastSaleColumns] = useState(['last_sale']);

  const [totalActive, setTotalActive] = useState(0);
  const [mainDoneList, setMainDoneList] = useState([]);
  const [mainfailList, setMainFailList] = useState([]);
  const [spin, setSpin] = useState(false);
  const [forceSpin, setForceSpin] = useState(false);

  const [bidFilterOperations] = useState([
    'equal',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'emptyValue'
  ]);

  const [lastSaleFilterOperations] = useState([
    'equal',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'noSaleInPast',
    'emptyValue'
  ]);

  const [nameFilterOperations] = useState([
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'equal',
    'in',
    'doesNotIn',
    'emptyValue'
  ]);

  const [dateFilterOperations] = useState([
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'emptyValue'
  ]);

  const [dynamicBidFilterOperations] = useState([
    'equal',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThanOrEqualMaxBid',
    'lessThanOrEqualMaxBid',
    'dynamicStatusIsActive',
    'dynamicDisabledAndGreaterThan',
    'emptyValue'
  ]);

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
    setEnterKeyForPurchaseEditor = false;
  };

  const [filterIndex, setFilterIndex] = useState(0);

  // for changing the groups
  const changeGrouping = value => {
    dispatch({ type: 'CHANGE_GROUPING', payload: value });
  };

  // for expanding each grouped data
  const setExpandedGroups = value => {
    dispatch({ type: 'SET_EXPANDED_GROUPS', payload: value });
    if (document.getElementById(filterFocus)) {
      filterFocus = '';
    }
    if (document.getElementById(rowFocus)) {
      rowFocus = '';
    }
  };

  // sort function to override the setSorting method
  const setSort = val => {
    setSortIndex(1);

    if (flag.length) {
      let dupVal = val;

      // if val gets multiple columnNames, we need to splice and remove to latest selected column
      if (val.length > 1) {
        val.forEach((e, i) => {
          if (JSON.stringify(e) === JSON.stringify(flag[0])) {
            dupVal.splice(i, 1);
            setSorting(dupVal);
            setFlag(dupVal);
            setCurrentPage(0);
          }
        });
      } else {
        setFlag(val);
        setSorting(val);
        setCurrentPage(0);
      }
    } else {
      setFlag(val);
      setSorting(val);
      setCurrentPage(0);
    }
  };

  const [index, setIndex] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);

  const setSearchState = value => {
    setIndex(1);
    searchValueNew = value;
    if (value === '') {
      setSearchValue(value);
      setCurrentPage(0);
    }
  };

  const handleSearch = () => {
    setSearchValue(searchValueNew);
    setCurrentPage(0);
  };

  const getCondition = (name, condition) => {
    console.log('getCondition', name, condition);
    let queryCondition = '';
    let queryField = '';
    switch (name) {
      case 'is_modeling_auto_bid_active':
        queryField = 'product__modeling_max_bid_amount';
        break;
      case 'status_image':
        queryField = 'product__active';
        break;
      case 'is_auto_bid_active':
        queryField = 'product__is_auto_bid_active';
        break;
      case 'release_year':
        queryField = 'year';
        break;
      case 'auto_bid_last_sync_modified':
        queryField = 'product__auto_bid_last_sync__date';
        break;
      case 'size':
        queryField = 'product__size';
        break;
      case 'name':
        queryField = 'title';
        break;
      case 'department':
        queryField = 'product_category';
        break;
      case 'parent_id':
        queryField = 'id';
        break;
      case 'chainId':
        queryField = 'product__chain_id';
        break;
      case 'bid_amount':
        queryField = 'product__bid_amount';
        break;
      case 'max_bid_amount':
        queryField = 'product__max_bid_amount';
        break;
      case 'lowest_ask':
        queryField = 'product__lowest_ask';
        break;
      case 'highest_bid':
        queryField = 'product__highest_bid';
        break;
      case 'last_sale':
        queryField = 'product__last_sale';
        break;
      case 'number_Of_asks':
        queryField = 'product__number_Of_asks';
        break;
      case 'number_of_bids':
        queryField = 'product__number_of_bids';
        break;
      default:
        queryField = name;
        break;
    }

    const fieldWithoutCondition = [
      'status_image',
      'is_auto_bid_active',
      'active'
    ];

    if (fieldWithoutCondition.includes(queryField)) {
      bulkUpdateFilterOperation = '-';
      return queryField;
    }

    switch (condition) {
      case 'greaterThan':
        bulkUpdateFilterOperation = 'Greater than';
        queryCondition = `${queryField}__gt`;
        break;
      case 'greaterThanOrEqual':
        bulkUpdateFilterOperation = 'Greater than or equal to';
        queryCondition = `${queryField}__gte`;
        break;
      case 'lessThan':
        bulkUpdateFilterOperation = 'Less than';
        queryCondition = `${queryField}__lt`;
        break;
      case 'lessThanOrEqual':
        bulkUpdateFilterOperation = 'Less than or equal to';
        queryCondition = `${queryField}__lte`;
        break;
      case 'contains':
        bulkUpdateFilterOperation = 'Contains';
        queryCondition = `${queryField}__icontains`;
        break;
      case 'notContains':
        bulkUpdateFilterOperation = 'Does not Contains';
        queryCondition = `exclude|${queryField}__icontains`;
        break;
      case 'startsWith':
        bulkUpdateFilterOperation = 'Starts with';
        queryCondition = `${queryField}__istartswith`;
        break;
      case 'endsWith':
        bulkUpdateFilterOperation = 'Ends with';
        queryCondition = `${queryField}__iendswith`;
        break;
      case 'equal':
        bulkUpdateFilterOperation = 'Equals';
        queryCondition = `${queryField}__iexact`;
        break;
      case 'in':
        bulkUpdateFilterOperation = 'In';
        queryCondition = `${queryField}__in`;
        break;
      case 'doesNotIn':
        bulkUpdateFilterOperation = 'Not in';
        queryCondition = `exclude|${queryField}__in`;
        break;
      case 'month':
        bulkUpdateFilterOperation = 'Month';
        queryCondition = `${queryField}__month`;
        break;
      case 'greaterThanOrEqualMaxBid':
        bulkUpdateFilterOperation =
          'Greater than or equal max bid amount';
        queryCondition = 'modeling_max_bid_amount_gt_max_bid_amount';
        break;
      case 'lessThanOrEqualMaxBid':
        bulkUpdateFilterOperation = 'Less than or equal max bid amount';
        queryCondition = 'modeling_max_bid_amount_lt_max_bid_amount';
        break;
      case 'dynamicStatusIsActive':
        bulkUpdateFilterOperation =
          'Greater than or equal max bid amount';
        queryCondition = `is_modeling_auto_bid_active`;
        break;
      case 'dynamicDisabledAndGreaterThan':
        bulkUpdateFilterOperation = 'Greater than or equal';
        queryCondition = `is_modeling_auto_bid_disabled`;
        break;
      case 'emptyValue':
        bulkUpdateFilterOperation = 'No Value';
        queryCondition = `${queryField}__isnull`;
        break;
      default:
        bulkUpdateFilterOperation = '-';
        queryCondition = `${queryField}__${condition}`;
        break;
    }

    return queryCondition;
  };

  const [btn, setBtn] = useState(false);
  const [listOfBids, setListOfBids] = useState([]);
  const [autoStatus, setAutoStatus] = useState([]);
  const [buttonText, setButtonText] = useState('');
  const [modelingAutoStatus, setModelingAutoStatus] = useState([]);

  const BidEditor = ({ value, onValueChange, row, column }) => {
    var data = {};
    statusEdited = false;
    autoBidEdited = false;

    const handleChange = event => {
      // noRetain = [];

      if (event.target.value === '') {
        BidEditorFilterStatus = true;
      }
      rowFocus = event.target.id;
      retainErrorListValue = [];
      if (event.target.name === 'is_auto_bid_active') {
        if (event.target.checked) {
          autoStatus.push(event.target.id);
          setAutoStatus(autoStatus);
        } else {
          let index = autoStatus.indexOf(event.target.id);
          if (index > -1) {
            autoStatus.splice(index, 1);
            setAutoStatus(autoStatus);
          }
        }
      }

      if (event.target.name === 'is_modeling_auto_bid_active') {
        if (event.target.checked) {
          modelingAutoStatus.push(event.target.id);
        } else {
          let index = modelingAutoStatus.indexOf(event.target.id);
          if (index > -1) {
            modelingAutoStatus.splice(index, 1);
          }
        }
        setModelingAutoStatus(modelingAutoStatus);
      }

      statusEdited = false;
      autoBidEdited = false;
      setMainDoneList([]);
      setMainFailList([]);

      if (row) {
        rowFocus = event.target.id;
        setBtn(true);
        if (listOfBids.length > 0) {
          // we maintain all changed row id in fakeList, and if this row.id exists in fakeList
          // then we filter that row and make the needed changes
          if (fakeList.includes(row.id)) {
            let index = fakeList.indexOf(row.id);
            let copy = listOfBids.filter(
              each => each.bid_id === row.id
            );
            let e1 = copy[0];
            const { name: modelName } = event.target;
            e1[modelName] =
              modelName === 'is_auto_bid_active' ||
                modelName === 'is_modeling_auto_bid_active'
                ? JSON.stringify(event.target.checked)
                : event.target.value;
            listOfBids.splice(index, 1);
            fakeList.splice(index, 1);
            fakeList.push(row.id);
            listOfBids.push(e1);
            setListOfBids(listOfBids);
          } else {
            data['bid_id'] = row.id;
            data[event.target.name] =
              event.target.name === 'is_auto_bid_active' ||
                event.target.name === 'is_modeling_auto_bid_active'
                ? JSON.stringify(event.target.checked)
                : event.target.value;
            if (Object.keys(data).length > 0) {
              listOfBids.push(data);
              fakeList.push(row.id);
              setListOfBids(listOfBids);
            }
          }
        } else {
          data['bid_id'] = row.id;
          data[event.target.name] =
            event.target.name === 'is_auto_bid_active' ||
              event.target.name === 'is_modeling_auto_bid_active'
              ? JSON.stringify(event.target.checked)
              : event.target.value;
          if (Object.keys(data).length > 0) {
            listOfBids.push(data);
            fakeList.push(row.id);
            setListOfBids(listOfBids);
          }
        }
      } else {
        filterFocus = event.target.id;
      }

      const { value: targetValue } = event.target;
      if (event.target.name === 'filter_is_auto_bid_active') {
        BidEditorFilterStatus = true;
        auto_active =
          event.target.name === 'filter_is_auto_bid_active' &&
          event.target.checked;
        autoBidEdited = true;
        if (auto_active) {
          filterAutoBidList.unshift('filter_is_auto_bid_active');
        } else {
          filterAutoBidList.shift(0);
        }
        onValueChange(event.target.name);
      } else if (
        event.target.name === 'filter_is_auto_bid_inactive'
      ) {
        BidEditorFilterStatus = true;
        auto_inactive =
          event.target.name === 'filter_is_auto_bid_inactive' &&
          event.target.checked;
        if (auto_inactive) {
          filterAutoBidList.push('filter_is_auto_bid_inactive');
        } else {
          filterAutoBidList.pop();
        }
        autoBidEdited = true;
        onValueChange(event.target.name);
      } else if (
        [
          'is_auto_bid_active',
          'is_modeling_auto_bid_active'
        ].includes(event.target.name)
      ) {
        onValueChange(event.target.checked);
      } else {
        autoBidEdited = false;
        onValueChange(targetValue);
      }
    };

    //Function to avoid negative values
    const handleKeypress = e => {
      const characterCode = e.key;

      if (e.which === 13) {
        setFilters(enterKeyValue);
        setCurrentPage(0);
      }
      if (characterCode === 'Backspace') return;

      const characterNumber = Number(characterCode);
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        }
      } else {
        e.preventDefault();
      }
    };

    // when selected each child, it specifies row
    if (row) {
      // if selected field is bid or max bid
      if (
        column['name'] === 'bid_amount' ||
        column['name'] === 'max_bid_amount'
      ) {
        const error = retainErrorListValue.filter(
          each => each.id === row.id
        );

        return (
          <input
            className="form-control text-right"
            id={column['name'] + row.id}
            value={
              noRetain.length > 0 && noRetain.includes(row.id)
                ? value
                : error[0] && column['name'] === 'bid_amount'
                  ? error[0].bidAmount
                  : error[0] && column['name'] === 'max_bid_amount'
                    ? error[0].maxBidAmount
                    : value
            }
            tabIndex="0"
            name={column['name']}
            autoFocus={column['name'] + row.id === rowFocus}
            type="number"
            min={0}
            onChange={handleChange}
            onKeyPress={e => handleKeypress(e)} //avoid negative values
          />
        );
      } else if (column['name'] === 'is_auto_bid_active') {
        // if selected field is auto bid
        return (
          <>
            {row['bid_amount'] && (
              <div className="bidHistory">
                <Icon
                  onClick={() => fetchBidHistory('bid', row.id)}
                  title="Bid History"
                >
                  history
                </Icon>
              </div>
            )}
            {row['max_bid_amount'] && (
              <div className="maxBidHistory">
                <Icon
                  onClick={() => fetchBidHistory('maxBid', row.id)}
                  title="Max Bid History"
                >
                  timeline
                </Icon>
              </div>
            )}
            <input
              type="checkbox"
              name={column['name']}
              id={row.id}
              onChange={handleChange}
              checked={autoStatus.includes(row.id) || value}
              title={
                autoStatus.includes(row.id) || value
                  ? 'Active'
                  : 'Inactive'
              }
            />
          </>
        );
      } else if (column['name'] === 'is_modeling_auto_bid_active') {
        // dynamic auto bid
        return (
          <>
            <div className="input-group modeling-max-bid">
              <input
                type="checkbox"
                name={column['name']}
                id={row.id}
                onChange={handleChange}
                checked={
                  modelingAutoStatus.includes(row.id) || value
                }
                title={
                  modelingAutoStatus.includes(row.id) || value
                    ? 'Active'
                    : 'Inactive'
                }
              />
              <label
                className="text-success max-bid-amount"
                for="modeling_max_bid_amount"
                title="Max Bid Amount"
              >
                {row['modeling_max_bid_amount']}
              </label>
            </div>
          </>
        );
      } else {
        // when a row is selected and other than above fields are clicked
        return <p>{row[column.name]}</p>;
      }
    } else {
      // editor input on filter for is_auto_bid_active
      if (column.name === 'is_auto_bid_active') {
        return (
          <div className="cu-checkbox">
            <input
              type="checkbox"
              style={{ width: '30px', height: '40px' }}
              id="active"
              name="filter_is_auto_bid_active"
              value="Active"
              checked={auto_active}
              onChange={handleChange}
              title="Active"
            />
            <label for="active" title="Active">
              Active
            </label>
            <input
              type="checkbox"
              style={{ width: '30px', height: '40px' }}
              id="inactive"
              name="filter_is_auto_bid_inactive"
              checked={auto_inactive}
              value="Inactive"
              onChange={handleChange}
              title="Inactive"
            />
            <label for="inactive" title="Inactive">
              Inactive
            </label>
          </div>
        );
      } else if (
        column.name === 'updated_on' ||
        column.name === 'id'
      ) {
        return '';
      } else {
        // filter for all other number field columns
        return (
          <input
            className="form-control text-right"
            type="number"
            id={column.name}
            tabIndex="0"
            placeholder="Filter..."
            value={value === undefined ? '' : value}
            min={0}
            onChange={handleChange}
            onKeyPress={e => handleKeypress(e)} //avoid negative values
          />
        );
      }
    }
  };

  // for retrieving the url of each page
  const getQueryString = () => {
    let sortingStr, groupConfig;
    // check for filter
    let filter = filters.reduce(
      (acc, { columnName, value, operation }) => {
        let filterName = getCondition(columnName, operation);
        let filterObject = {};
        if (columnName === 'status_image') {
          if (filterList.length === 0 || filterList.length === 2) {
            filterObject = {};
          } else {
            filterObject[filterName] =
              filterList[0] === 'active' ? 1 : 0;
          }
        } else if (columnName === 'is_auto_bid_active') {
          if (
            filterAutoBidList.length === 0 ||
            filterAutoBidList.length === 2
          ) {
            filterObject = {};
          } else {
            filterObject[filterName] =
              filterAutoBidList[0] === 'filter_is_auto_bid_active'
                ? 'True'
                : 'False';
          }
        } else {
          if (operation === 'in' || operation === 'doesNotIn') {
            var listIt = [];
            var splitValues = value.split(',');
            splitValues.forEach(each => {
              listIt.push(each.trim(' '));
            });

            filterObject[filterName] = listIt;
          } else {
            filterObject[filterName] = encodeURIComponent(
              value.trim()
            );
          }
        }

        acc.push(filterObject);

        for (let i = 0; i < columns.length; i++) {
          if (columns[i].name === columnName) {
            if (value === 'filter_is_auto_bid_active') {
              value = 'active';
            } else if (value === 'filter_is_auto_bid_inactive') {
              value = 'inactive';
            }

            showBlukUpdate(
              value,
              columns[i].title,
              bulkUpdateFilterOperation
            );
          }
        }

        return acc;
      },
      []
    );

    // check for search
    let search = columns.reduce(() => {
      return searchValue;
    }, []);

    if (columns.length > 1) {
      search = `[${JSON.stringify(search.trim())}]`;
    }

    // check for sorting
    if (sorting.length) {
      const sortingConfig = sorting.map(
        ({ columnName, direction }) => {
          let colName =
            columnName === 'name'
              ? 'title'
              : columnName === 'department'
                ? 'product_category'
                : columnName === 'release_year'
                  ? 'year'
                  : columnName === 'auto_bid_last_sync_modified'
                    ? 'auto_bid_last_sync'
                    : columnName === 'is_modeling_auto_bid_active'
                      ? 'modeling_max_bid_amount'
                      : columnName;
            if (columnName.includes("created_on")) {
              colName = 'created'
            }
            if (columnName.includes("updated_on")) {
              colName = 'updated'
            }
          return direction === 'desc' ? `-${colName}` : colName;
        }
      );
      sortingStr = JSON.stringify(sortingConfig);
    } else {
      sortingStr = JSON.stringify(['']);
    }

    // check for grouping
    if (grouping.length) {
      groupConfig = grouping.map(columnGrouping => ({
        selector: columnGrouping.columnName,
        isExpanded: true
      }));
    } else {
      groupConfig = [];
    }

    bulkUpdate =
      filter.length > 0 || JSON.parse(search)[0] !== ''
        ? true
        : false;

    return diffShowing
      ? `${URL}?group=${JSON.stringify(
        groupConfig
      )}&order_by=${sortingStr}&search=${search}&filter=${JSON.stringify(
        filter
      )}&page=${currentPage +
      1}&limit=${pageSize}&enable_buying=True&enable_diff=true`
      : `${URL}?group=${JSON.stringify(
        groupConfig
      )}&order_by=${sortingStr}&search=${search}&filter=${JSON.stringify(
        filter
      )}&page=${currentPage +
      1}&limit=${pageSize}&enable_buying=True`;
  };

  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  const fetchData = () => {
    const queryString = getQueryString();

    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    fetch(queryString, {
      method: 'get',
      headers
    })
      .then(response => response.json())
      .then(orders => {
        setTotalActive(orders.total_products);
        dispatch({ type: 'FETCH_SUCCESS', payload: orders });
      })
      .catch(error => {
        console.log(error);
        Sentry.captureException('Buying Fetch API ' + error);
        dispatch({ type: 'FETCH_ERROR' });
        const sweet = (
          <SweetAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error"
            onConfirm={() => hideAlert()}
            confirmBtnText="OK"
          >
            <h4>Error in fetching data!</h4>
          </SweetAlert>
        );
        setSweetAlert(sweet);
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);
    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      bulkUpdate = false;
      filterAutoBidList = [];
      statusEdited = false;
      autoBidEdited = false;
      selectedDate = '';
      activeValue = false;
      inActiveValue = false;
      auto_active = false;
      auto_inactive = false;
      diffShowing = false;
      bidFields = false;
      setEnterKeyForPurchaseEditor = false;
      var projections = document.getElementsByTagName('body')[0];
      projections.classList.remove('projectIcon'); // removing the ProjectIcon (DIFF) icon to show as highlighted
      var testElements = document.getElementsByTagName('body')[0];
      testElements.classList.remove('hideAddition'); // removing the hide class placed on Products tab
    }
    loadData();
    if (document.getElementById(filterFocus)) {
      document.getElementById(filterFocus).focus();
    }
    if (document.getElementById(rowFocus)) {
      document.getElementById(rowFocus).focus();
    }
  });

  const handleBulkDelete = (data, listOfChildIds) => {
    setSweetAlert('');
    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    // when updated row is getting deleted, we need to remove the item from listOfBids
    // let filteredListOfBids = listOfBids.filter(each => each.bid_id !== id);
    let filteredListOfBids = listOfBids.filter(
      each => !listOfChildIds.includes(each.bid_id)
    );
    setListOfBids(filteredListOfBids);
    filteredListOfBids.length === 0 && setBtn(false);

    axios(BULK_DELETE_API_URL, {
      data,
      headers,
      method: 'post'
    })
      .then(response => {
        bulkMessage = response.data;
        fetchDeleteSuccessMessage(response, true);
      })
      .catch(error => {
        fetchErrorMessage(error, '', 'delete');
      });
  };

  // function to return Bid and Max bid history
  const fetchBidHistoryResponse = (response, status) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={bidFields ? 'Bid History' : 'Max Bid History'}
        onConfirm={() => hideAlert()}
        confirmBtnText="OK"
      >
        {status !== 'error' ? (
          <>
            <h5 classNme="text-left">{response.data.label}</h5>
            <table class="table bidhishead">
              <tr>
                {bidFields ? (
                  <>
                    <td>Previous Bid</td>
                    <td>Current Bid</td>
                    <td>Mode</td>
                    <td>Max Bid</td>
                    <td>Created on</td>
                  </>
                ) : (
                  <>
                    <td>Previous Max Bid</td>
                    <td>Current Max Bid</td>
                    <td>Mode</td>
                    <td>Created on</td>
                  </>
                )}
              </tr>
            </table>
            <div className="bidHistoryCScroll">
              <table class="table">
                <tbody>
                  {response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.length > 0 &&
                    response.data.data.map((each, index) => (
                      <tr>
                        {bidFields ? (
                          <>
                            <td>{each['previous_bid_amount']}</td>
                            <td>{each['current_bid_amount']}</td>
                            <td>{each['placed_by']}</td>
                            <td>{each['max_bid']}</td>
                            <td>{each['created_on']}</td>
                          </>
                        ) : (
                          <>
                            <td>{each['previous_max_bid']}</td>
                            <td>{each['current_max_bid']}</td>
                            <td className="PercentageMaxBid">
                              {each['placed_by']}{' '}
                              {Math.sign(
                                each['increased_or_decreased_by']
                              ) === 1 ? (
                                <div>
                                  <span class="material-icons upPercent">
                                    arrow_upward
                                  </span>
                                  {each['increased_or_decreased_by']}{' '}
                                  %
                                </div>
                              ) : Math.sign(
                                each['increased_or_decreased_by']
                              ) === -1 ? (
                                <div>
                                  <span class="material-icons downPercent">
                                    arrow_downward
                                  </span>
                                  {each['increased_or_decreased_by']}{' '}
                                  %
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                            <td>{each['created_on']}</td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>{' '}
          </>
        ) : (
          ''
        )}
        <div>
          {response.length > 0 &&
            response.map(item => (
              <>
                <p>Product({item.productId}) found with error </p>
                <p> {item.msg}</p>
              </>
            ))}
        </div>
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // function to call Bid/Max Bid history API's
  const fetchBidHistory = (apiUrl, childId) => {
    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    let data = { product_id: childId };
    bidFields = apiUrl === 'bid' ? true : false;
    let bidURL =
      apiUrl === 'bid'
        ? BID_HISTORY_API_URL
        : MAX_BID_HISTORY_API_URL;

    axios(bidURL, {
      method: 'post',
      headers,
      data
    })
      .then(response => {
        fetchBidHistoryResponse(response, '');
      })
      .catch(error => {
        Sentry.captureException(
          'Buying Page Bid History API ' + error
        );
        if (checkFunction(error)) {
          errors = [];
          errors.push({
            productId: data.product_id,
            msg: 'Bid History API ' + error.message
          });
          fetchBidHistoryResponse(errors, 'error');
        } else {
          fetchMessage(error, true, '');
        }
      });
  };

  // formatting bid amount
  const BidFormatter = ({ value, row }) =>
    row['bid_amount_exceeded'] ? (
      <p style={{ color: 'red' }}>{value}</p>
    ) : (
      <p>{value}</p>
    );

  // formatting auto bid sync date
  const AutoSyncFormatter = ({ value }) => {
    return value === '' ? '' : <p>{value}</p>;
  };

  // formatting bid amount
  const MaxBidFormatter = ({ value, row }) => {
    return (
      <p>{value}</p>
    );
  };

  const [copiedVal, setCopiedVal] = useState('');

  const copytext = titleVal => {
    copy(titleVal);
    setCopiedVal(titleVal);
    setButtonText('Copied !');
  };

  // to show custom formating last sale field
  const lastSaleFormatter = ({ value, row }) => {
    const last_sale_history = row['last_sale_history'];
    return (
      <>
        {last_sale_history.map((item, index) => {
          return (
            <span>
              {index + 1} -{' '}
              {item['number_of_sale'] && item['number_of_sale']}{' '}
              <strong className="proxySuccess mr-2">
                {item['last_sale']}{' '}
              </strong>{' '}
              {item['updated_on']}
              <br />
            </span>
          );
        })}
      </>
    );
  };

  const LastSaleTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={lastSaleFormatter}
      availableFilterOperations={lastSaleFilterOperations}
      editorComponent={BidEditor}
      {...props}
    />
  );

  // to show custom formatting in image field in each expanded tables
  const ImageFormatter = ({ value }) => {
    let listOfChildIds = [];
    value[5].forEach(eachChildId =>
      listOfChildIds.push(eachChildId.id)
    );
    let data = { parent_product: value[3] };
    return (
      <>
        <a
          href={value[2]}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
        >
          <img src={value[1]} alt="" />
        </a>
        <span>{value[0]}</span>

        <Icon
          onClick={() => fetchSyncData(data)}
          className="synsBtn"
          title="Sync"
        >
          sync
        </Icon>
        <i title="Bulk Delete">
          <DeleteIcon
            onClick={() => fetchWarning(data, true, listOfChildIds)} //3rd param is listOfChildIds
            className="rowdel"
            title="Bulk Delete"
          />
        </i>
        <i className="clipboard" title="Copy">
          {value[0] === copiedVal && buttonText ? (
            <span>{buttonText}</span>
          ) : (
            <FileCopyIcon onClick={() => copytext(value[0])} />
          )}
        </i>
      </>
    );
  };

  // capability to customize formatting
  const ImageTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={ImageFormatter}
      {...props}
    />
  );

  // to show custom formatting in image field in each expanded tables
  const StatusFormatter = ({ value, row }) => (
    <img
      src={window.location.origin + value}
      alt=""
      title={row['status'] ? 'Active' : 'Inactive'}
    />
  );

  // capability to customize formatting
  const StatusTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={StatusFormatter}
      {...props}
    />
  );

  // to show custom formatting in image field in each expanded tables
  const AutoBidActiveFormatter = ({ value, row }) => {
    return (
      <>
        {row['bid_amount'] && (
          <div className="bidHistory">
            <Icon
              onClick={() => fetchBidHistory('bid', row.id)}
              title="Bid History"
            >
              history
            </Icon>
          </div>
        )}
        {row['max_bid_amount'] && (
          <div className="maxBidHistory">
            <Icon
              onClick={() => fetchBidHistory('maxBid', row.id)}
              title="Max Bid History"
            >
              timeline
            </Icon>
          </div>
        )}
        <input
          type="checkbox"
          checked={value}
          title={value ? 'Active' : 'Inactive'}
        />
      </>
    );
  };

  // capability to customize formatting
  const AutoBidActiveProvider = props => (
    <DataTypeProvider
      formatterComponent={AutoBidActiveFormatter}
      {...props}
    />
  );

  // to show custom formatting in image field in each expanded tables
  const ModelingAutoBidActiveFormatter = ({ value, row }) => {
    const modeling_max_bid_amount = row['modeling_max_bid_amount'];
    return (
      <>
        {modeling_max_bid_amount > 0 && (
          <div className="input-group modeling-max-bid">
            <input
              type="checkbox"
              checked={value}
              title={value ? 'Active' : 'Inactive'}
            />
            <label
              className="text-success max-bid-amount"
              for="modeling_max_bid_amount"
              title="Max Bid Amount"
            >
              {modeling_max_bid_amount}
            </label>
          </div>
        )}
      </>
    );
  };

  // capability to customize modeling auto bid
  const ModelingAutoBidActiveProvider = props => (
    <DataTypeProvider
      formatterComponent={ModelingAutoBidActiveFormatter}
      {...props}
    />
  );

  // function to hide the popups
  const hideAlert = alertStatus => {
    setSweetAlert('');
    (mainDoneList.length > 0 || alertStatus === 'success') &&
      fetchData();
    setMainDoneList([]);
    setMainFailList([]);
    setErrorMessages([]);
    bidUpdateStatus = [];
    bulkMessage = [];
    retainErrorListValue = mainfailList;
    // alertStatus === "success" && fetchData();
  };

  var lenOfBids = 0;

  const messageToShow = (value, forceStatus, status, errorData) => {
    // var dupList = [];
    dispatch({ type: 'FETCH_ERROR' });

    if (status === 'otherResponse') {
      var label = value.response.data.label
        ? value.response.data.label + ' : '
        : '';

      if (forceStatus) {
        mainfailList.forEach(each => {
          if (each.id === value.response.data.id) {
            each['label'] = label + value.response.data.message;
            each['showForce'] = value.response.data.show_force_update
              ? value.response.data.show_force_update
              : '';
            each['id'] = value.response.data.id;
            each['bidAmount'] = value.response.data.bid_amount
              ? value.response.data.bid_amount
              : '';
            each['newBidAmount'] =
              value.response.data.new_bid_amount;
            each['maxBidAmount'] =
              value.response.data.max_bid_amount;
            each['otherMessages'] = value.response.data
              .other_messages
              ? value.response.data.other_messages
              : '';
          }
        });
        setForceSpin(false);
        setSpin(false);
      } else {
        //FOR DOING MODAL
        var dataToShowForceUpdate = {};
        dataToShowForceUpdate['label'] =
          label + value.response.data.message;
        dataToShowForceUpdate['showForce'] =
          value.response.data.show_force_update;
        dataToShowForceUpdate['id'] = value.response.data.id;
        dataToShowForceUpdate['bidAmount'] =
          value.response.data.bid_amount;
        dataToShowForceUpdate['newBidAmount'] =
          value.response.data.new_bid_amount;
        dataToShowForceUpdate['maxBidAmount'] =
          value.response.data.max_bid_amount;
        dataToShowForceUpdate['otherMessages'] = value.response.data
          .other_messages
          ? value.response.data.other_messages
          : '';
        mainfailList.push(dataToShowForceUpdate);
      }
    }
    if (status === 'update404Response') {
      bidUpdateStatus.push({ errorData });
      setErrorMessages(bidUpdateStatus);
      setSpin(false);
      setForceSpin(false);
    }

    if (lenOfBids === mainfailList.length + mainDoneList.length) {
      setSpin(false);
    }
    // setFailList(dupList);
    setBtn(false);
  };

  // function to show warning popups
  const fetchWarning = (data, status, idOrListOfChildIds) => {
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={
          status
            ? 'Are you sure you want to delete group of bids?'
            : 'Are you sure you want to delete this bid?'
        }
        onConfirm={() =>
          status
            ? handleBulkDelete(data, idOrListOfChildIds)
            : handleItemDelete(data, idOrListOfChildIds)
        }
        onCancel={() => hideAlert()}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        <ErrorOutlineIcon className="errorOutlineIcon" />
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // function to post api for getting sync of particular product
  const fetchSyncData = data => {
    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    axios(PRODUCT_SYNC_API_URL, {
      method: 'post',
      data,
      headers
    })
      .then(response => {
        fetchMessage(response, true, 'reload');
      })
      .catch(error => {
        Sentry.captureException(
          'Buying Page Sync Data API posting ' + error
        );
        if (checkFunction(error)) {
          // alert("Sync Products API " + error);
          errors = [];
          errors.push({
            id: data.parent_product,
            msg: 'Sync Products API ' + error.message
          });
          fetchMessage(errors, true, 'errorResponse');
        } else {
          fetchMessage(error, true, '');
        }
      });
  };

  // to show success message in edit/delete
  const fetchSuccessMessage = (response, forceStatus) => {
    dispatch({ type: 'FETCH_ERROR' });
    var label = response.data.label
      ? response.data.label + ' : '
      : '';
    if (forceStatus) {
      var listofFails = mainfailList.filter(
        each => each.id !== response.data.id
      );
      // setFailList(listofFails);
      setMainFailList(listofFails);
      setSpin(false);
      setForceSpin(false);
    }
    mainDoneList.push(label + response.data.message);
    if (lenOfBids === mainfailList.length + mainDoneList.length) {
      setSpin(false);
    }
    // setDoneList(dupDoneList);
    setBtn(false);
  };

  // to show error message in sweet alert for delete process
  const fetchMessage = (value, status, stateOfLoadData) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        showConfirm
        showCancel={false}
        onConfirm={() =>
          stateOfLoadData === 'reload'
            ? hideAlert('success')
            : hideAlert('error')
        }
        style={{ display: 'block', marginTop: '-100px' }}
      >
        {status && stateOfLoadData === 'reload' && (
          <h4>{value.data.label}</h4>
        )}
        {status && stateOfLoadData === '' && (
          <h4>{value.response.data.label}</h4>
        )}
        {stateOfLoadData === 'reload' ? (
          <h3>{value.data.message}</h3>
        ) : (
          <h3>
            {value.response ? value.response.data.message : ''}
          </h3>
        )}
        <br />
        {status && stateOfLoadData === 'reload' ? (
          <CheckOutlinedIcon className="errorOutlineIcon completed" />
        ) : (
          <ErrorOutlineIcon className="errorOutlineIcon" />
        )}
        {status && stateOfLoadData === 'errorResponse'
          ? value.length > 0 &&
          value.map(item => (
            <>
              <p>Product({item.id}) found with error </p>
              <p> {item.msg}</p>
            </>
          ))
          : ''}
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // to show error message in edit/delete
  const fetchErrorMessage = (error, forceStatus, process, data) => {
    Sentry.captureException('Buying Bid Update ' + error);
    if (checkFunction(error)) {
      // alert("Buying: Error on bid update, after 404 or 500 response " + error)
      // window.location = "/admin/error";
      if (process === 'delete') {
        dispatch({ type: 'FETCH_ERROR' });
        setShow(true);
      } else {
        errorResponseData = {
          bidId: data.bid_id,
          msg: error.message
        };
        messageToShow(
          '',
          forceStatus,
          'update404Response',
          errorResponseData
        );
      }
    } else {
      process === 'update'
        ? messageToShow(error, forceStatus, 'otherResponse', '')
        : fetchMessage(error, false, '');
    }
  };

  // function to update the bid amount
  const fetchBidUpdate = (data, forceStatus) => {
    noRetain = [];
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    forceStatus && setForceSpin(true);

    axios(BUY_UPDATE_API_URL, {
      data,
      headers,
      method: 'post'
    })
      .then(response => {
        fetchSuccessMessage(response, forceStatus);
      })
      .catch(error => {
        fetchErrorMessage(error, forceStatus, 'update', data);
      });
  };

  // function to invoke each bid amount for update
  const fetchNewBulk = () => {
    setSpin(true);
    lenOfBids = listOfBids.length;
    listOfBids.forEach((each, index) => {
      if (index + 1 === listOfBids.length) {
        fakeList = [];
        setListOfBids([]);
      }
      fetchBidUpdate(each);
    });
  };

  // to show success message in edit/delete
  const fetchDeleteSuccessMessage = (response, status) => {
    dispatch({ type: 'FETCH_ERROR' });
    // focus setted as null, after delete is succeeded
    filterFocus = '';
    rowFocus = '';
    const sweet = (
      <SweetAlert
        // success
        showConfirm
        title={status && 'Bulk Deletion Status'}
        onConfirm={() => hideAlert('success')}
        confirmBtnText="OK"
        style={{ display: 'block', marginTop: '-200px' }}
      >
        {!status && (
          <>
            <CheckOutlinedIcon className="errorOutlineIcon completed" />
            <br />
          </>
        )}
        {status &&
          bulkMessage &&
          bulkMessage.failed_message.length > 0 &&
          bulkMessage.failed_message.map(each => (
            <div className="sa-show">
              <SnackbarContent
                key={each.product_id}
                message={each.name + ' : ' + each.message}
                color="danger"
                className="warning-box"
              />
              <ErrorIcon />
            </div>
          ))}
        {status &&
          bulkMessage &&
          bulkMessage.success_message.length > 0 &&
          bulkMessage.success_message.map(each => (
            <div className="sa-show">
              <SnackbarContent
                key={each.product_id}
                message={each.name + ' : ' + each.message}
                color="success"
              />
              <DoneIcon className="tick-green" />
            </div>
          ))}
        {!status && <h2>{response.data.message}</h2>}
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // function to delete the items
  const handleItemDelete = (data, id) => {
    setSweetAlert('');
    noRetain.push(id);
    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    // when updated row is getting deleted, we need to remove the item from listOfBids
    let filteredListOfBids = listOfBids.filter(
      each => each.bid_id !== id
    );
    setListOfBids(filteredListOfBids);
    filteredListOfBids.length === 0 && setBtn(false);

    axios(BUY_DELETE_API_URL, {
      data,
      headers,
      method: 'post'
    })
      .then(response => {
        fetchDeleteSuccessMessage(response, false);
      })
      .catch(error => {
        noRetain.pop(id);
        fetchErrorMessage(error, '', 'delete', data);
      });
  };

  // to handle the edit and delete functionalities in each table rows
  const commitChanges = ({ deleted }) => {
    let data;
    if (deleted) {
      data = { bid_id: deleted[0] }; // getting this value from getRowId method
      fetchWarning(data, false, deleted[0]); // 3rd param is id
    }
  };

  const {
    data,
    expandedGroups,
    tempGrouping,
    tempExpandedGroups,
    grouping,
    loading,
    totalCount
  } = state;

  // function for setting current page
  const setNowPage = val => {
    setIndex(index + 1);
    setFilterIndex(filterIndex + 1);
    sortIndex === 1 && setSortIndex(sortIndex + 1);
    setCurrentPage(val);
  };

  const getFilters = val => {
    setFilterIndex(1);
    let statusDupList = [];
    let autoBidDupList = [];
    if (val.length > 0 && statusEdited) {
      val.forEach(each => {
        if (each.columnName === 'status_image') {
          if (filterList.includes(each['value'])) {
            statusDupList = filterList.filter(
              e => e !== each['value']
            );
            filterList = statusDupList;
          } else {
            filterList.push(each['value']);
          }
        }
        setFilters(val);
        setCurrentPage(0);
      });
    } else if (val.length > 0 && autoBidEdited) {
      val.forEach(each => {
        if (each.columnName === 'is_auto_bid_active') {
          if (filterAutoBidList.includes(each['value'])) {
            autoBidDupList = filterAutoBidList.filter(
              e => e !== each['value']
            );
            filterAutoBidList = autoBidDupList;
          } else {
            filterAutoBidList.push(each['value']);
          }
        }
        setFilters(val);
        setCurrentPage(0);
      });
    } else {
      if (BidEditorFilterStatus === true) {
        setFilters(val);
        setCurrentPage(0);
        BidEditorFilterStatus = false;
      }

      setEnterKeyValue(val);
    }
  };

  const sweetMessage = (
    <SweetAlert
      style={{ display: 'block', marginTop: '-200px' }}
      title={'Bid Updation Status'}
      showCancel={false}
      showConfirm={false}
    >
      {spin && (
        <span className="spinner-box">
          <span>
            <img src={spinner} alt="Loading" />
            Please wait! Bulk updating is on process
          </span>
        </span>
      )}
      {forceSpin && (
        <span className="spinner-box">
          <span>
            <img src={spinner} alt="Loading" />
            Please wait! Force updating is on process
          </span>
        </span>
      )}
      <div className={forceSpin ? 'sa-hide' : 'sa-show'}>
        {mainfailList.length > 0 &&
          mainfailList.map(e => (
            <>
              <SnackbarContent
                key={e.label}
                message={e.label + e.otherMessages}
                color="danger"
                className="warning-box"
              />
              <ErrorIcon />
              {e.showForce && (
                <button
                  onClick={() =>
                    fetchBidUpdate(
                      {
                        bid_id: e.id,
                        bid_amount: e.newBidAmount,
                        force_update: '1'
                      },
                      'forceUpdate'
                    )
                  }
                >
                  Force Update
                </button>
              )}
            </>
          ))}
        {mainDoneList.length > 0 &&
          mainDoneList.map(e => (
            <>
              <SnackbarContent key={e} message={e} color="success" />
              <DoneIcon className="tick-green" />
            </>
          ))}
        {errorMessages.length > 0 &&
          errorMessages.map(item => (
            <>
              <p>
                Buying: Error on bid update, after 404 or 500
                response{' '}
              </p>
              <p>
                Product({item.errorData.bidId}) found with error{' '}
              </p>
              <p>{item.errorData.msg}</p>
            </>
          ))}
        <p className="cancelBtn">
          {!spin && !forceSpin && (
            <button type="button" onClick={() => hideAlert()}>
              OK
            </button>
          )}
        </p>
      </div>
    </SweetAlert>
  );

  const handleDiff = () => {
    diffShowing = !diffShowing;
    let projectDiffs;
    if (diffShowing) {
      projectDiffs = document.getElementsByTagName('body')[0];
      projectDiffs.className += 'projectIcon';
    } else {
      projectDiffs = document.getElementsByTagName('body')[0];
      projectDiffs.classList.remove('projectIcon');
    }
    fetchData();
  };

  const fetchBulkUpdateMessage = (value, status) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        showConfirm
        showCancel={false}
        onConfirm={() => hideAlert()}
        style={{ display: 'block', marginTop: '-100px' }}
      >
        {status ? (
          <h4>{value.data.message}</h4>
        ) : (
          <h4>{value.response.data.message}</h4>
        )}
        {status ? (
          <CheckOutlinedIcon className="errorOutlineIcon completed" />
        ) : (
          <ErrorOutlineIcon className="errorOutlineIcon" />
        )}
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  const [globalMaxBid, setGlobalMaxBid] = useState({});

  const handleInputChange = event => {
    // remove h6 tab, so that it will hide the text from user

    var child = document.getElementsByTagName('h6')[0];
    child.classList.remove('h6ClassToShow');

    let name = event.target.name;
    if (name === 'global_max_bid' && event.target.value) {
      globalMaxBid[name] = event.target.value;
    } else if (event.target.value) {
      bulkData[name] = event.target.value;
    } else {
      delete bulkData[name];
      name === 'global_max_bid' && delete globalMaxBid[name];
    }
    setBulkData(bulkData);

    name === 'global_max_bid' && setGlobalMaxBid(globalMaxBid);
  };

  const handleBulkUpdateAction = updateStatus => {
    let checkCondition =
      updateStatus === 'bulk'
        ? Object.entries(bulkData).length > 1
        : Object.entries(globalMaxBid).length > 0;
    const queryString = getQueryString();
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    if (updateStatus === 'bulkProducts') {
      const bulkUrl = `${queryString}&bulk_delete=True`;
      axios
        .get(bulkUrl, {
          method: 'get',
          headers
        })
        .then(response => {
          fetchBulkUpdateMessage(response, true);
        })
        .catch(error => {
          fetchBulkUpdateMessage(error, false);
        });
    } else if (checkCondition) {
      dispatch({ type: 'LOADING' });
      setSweetAlert('');
      let bulkStringified = JSON.stringify(bulkData);
      let globalStringified =
        Object.entries(globalMaxBid).length > 0 &&
        globalMaxBid['global_max_bid'];
      const bulkUrl =
        updateStatus === 'bulk'
          ? `${queryString}&bulk_update=True&bulk_update_data=[${bulkStringified}]`
          : `${queryString}&global_update=True&global_update_percentage=${globalStringified}`;
      axios
        .get(bulkUrl, {
          method: 'get',
          headers
        })
        .then(response => {
          setBulkData({ page_type: 'current' }); // setting prev val for bulk update
          setGlobalMaxBid({}); // setting global bid value to initial value
          fetchBulkUpdateMessage(response, true);
        })
        .catch(error => {
          setBulkData({ page_type: 'current' }); // setting prev val for bulk update
          setGlobalMaxBid({}); // setting global bid value to initial value
          fetchBulkUpdateMessage(error, false);
        });
    } else {
      var para = document.getElementsByTagName('h6')[0];
      para.className += ' h6ClassToShow';
    }
  };

  // Function to handle negative values
  const handleNegative = e => {
    const characterCode = e.key;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const fetchGlobalUpdate = () => {
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Increase or Decrease Max Bid Globally"
        onConfirm={() => handleBulkUpdateAction('global')}
        onCancel={() => hideAlert()}
        showCancel={true}
        confirmBtnText="Submit"
      >
        <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <h6>Please provide values for Max Bid (in %)</h6>
          <div className="row">
            <GridItem xs={12} sm={12} md={12}>
              <table className="col-md-12">
                <tr>
                  <td>Max Bid (in %)</td>
                  <td>
                    <input
                      type="number"
                      id="floatGlobalBid"
                      name="global_max_bid"
                      onChange={event => handleInputChange(event)}
                      style={{ width: '50%' }}
                    />
                  </td>
                </tr>
              </table>
            </GridItem>
          </div>
        </GridItem>
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // if user clicks No btn in bulk upadate
  const openBulkUpdatePrev = () => {
    hideAlert();
    fetchBulkUpdate();
  };

  // for Confirm the Bulk update Data. open after click submit btn
  const openBulkUpdateConfirm = updateStatus => {
    let checkCondition =
      updateStatus === 'bulk'
        ? Object.entries(bulkData).length > 1
        : Object.entries(globalMaxBid).length > 0;

    if (checkCondition) {
      hideAlert();

      const sweet = (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Bulk Update"
          onConfirm={() => handleBulkUpdateAction('bulk')}
          onCancel={() => openBulkUpdatePrev()}
          showCancel
          confirmBtnText="Yes"
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
            <h6>
              Please provide values for Bid Amount, Max Bid or Auto
              Bid
            </h6>
            <div className="row">
              <GridItem xs={12} sm={12} md={12}>
                <div className="yourFilter">
                  <h5 className="text-center">
                    Your Current Filters
                  </h5>

                  <table className="col-md-12">
                    <thead>
                      <tr>
                        <td>Filter</td>
                        <td>Operation</td>
                        <td>Value</td>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkUpdateArray.map(res => {
                        return (
                          <tr>
                            <td>{res.title}</td>
                            <td>{res.operation}</td>
                            <td>{res.value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <table className="col-md-12">
                  <tr>
                    <td>Bid Amount : </td>
                    <td>{bulkData.auto_bid}</td>
                  </tr>
                  <tr>
                    <td>Max Bid : </td>
                    <td> {bulkData.max_bid}</td>
                  </tr>
                  <tr>
                    <td>Auto Bid : </td>
                    <td>
                      {' '}
                      <div className="cu-checkbox text-left">
                        {bulkData.auto_bid_status === 'True'
                          ? 'Active'
                          : bulkData.auto_bid_status === 'False'
                            ? 'In Active'
                            : ''}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Apply For : </td>
                    <td>
                      <div className="cu-checkbox text-left">
                        {' '}
                        {bulkData.current}
                        {bulkData.page_type}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Count : </td>
                    <td>
                      <div className="cu-checkbox text-left">
                        {' '}
                        {totalCount}
                      </div>
                    </td>
                  </tr>
                </table>
                <h5>
                  Please confirm before submitting your request
                </h5>
              </GridItem>
            </div>
          </GridItem>
        </SweetAlert>
      );
      setSweetAlert(sweet);
    } else {
      var para = document.getElementsByTagName('h6')[0];
      para.className += ' h6ClassToShow';
    }
  };

  const fetchBulkUpdate = () => {
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Bulk Update"
        onConfirm={() => openBulkUpdateConfirm('bulk')}
        onCancel={() => hideAlert()}
        confirmBtnText="Submit"
      >
        <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <h6>
            Please provide values for Bid Amount, Max Bid or Auto Bid
          </h6>
          <div className="row">
            <GridItem xs={12} sm={12} md={12}>
              <div className="yourFilter">
                <h5 className="text-center">Your Current Filters</h5>

                <table className="col-md-12">
                  <thead>
                    <tr>
                      <td>Filter</td>
                      <td>Operation</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    {bulkUpdateArray.map(res => {
                      return (
                        <tr>
                          <td>{res.title}</td>
                          <td>{res.operation}</td>
                          <td>{res.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <table className="col-md-12">
                <tr>
                  <td>Bid Amount</td>
                  <td>
                    <input
                      type="number"
                      id="float"
                      onKeyPress={e => handleNegative(e)}
                      name="auto_bid"
                      min={0}
                      onChange={event => handleInputChange(event)}
                      style={{ width: '50%' }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Max Bid</td>
                  <td>
                    {' '}
                    <input
                      type="number"
                      id="float"
                      min={0}
                      onKeyPress={e => handleNegative(e)}
                      name="max_bid"
                      onChange={event => handleInputChange(event)}
                      style={{ width: '50%' }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Auto Bid</td>
                  <td>
                    {' '}
                    <div className="cu-checkbox text-left">
                      <input
                        type="radio"
                        style={{ width: '20px', height: '15px' }}
                        id="activeBid"
                        name="auto_bid_status"
                        value="True"
                        onChange={handleInputChange}
                        title="Active"
                      />
                      <label for="active" title="Active">
                        Active
                      </label>
                      <input
                        type="radio"
                        style={{
                          width: '20px',
                          height: '15px',
                          marginLeft: '20px'
                        }}
                        id="inactiveBid"
                        name="auto_bid_status"
                        value="False"
                        onChange={handleInputChange}
                        title="Inactive"
                      />
                      <label for="inactive" title="Inactive">
                        Inactive
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Apply For</td>
                  <td>
                    <div className="cu-checkbox text-left">
                      <input
                        type="radio"
                        style={{ width: '20px', height: '15px' }}
                        id="currentPageCheck"
                        defaultChecked
                        name="page_type"
                        value="current"
                        onChange={handleInputChange}
                        title="Current"
                      />
                      <label for="currentPageCheck" title="Current">
                        Current
                      </label>
                      <input
                        type="radio"
                        style={{
                          width: '20px',
                          height: '15px',
                          marginLeft: '20px'
                        }}
                        id="allPageCheck"
                        name="page_type"
                        value="all"
                        onChange={handleInputChange}
                        title="All"
                      />
                      <label for="allPageCheck" title="All">
                        All
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </GridItem>
          </div>
        </GridItem>
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  const fetchBulkUpdateWarning = () => {
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure you want to delete this filtered products?"
        onConfirm={() => handleBulkUpdateAction('bulkProducts')}
        onCancel={() => hideAlert()}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        <ErrorOutlineIcon className="errorOutlineIcon" />
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  return (
    <>
      {totalActive > 0 && (
        <span className="buying count" title="Total Active">
          <NumberFormat
            value={totalActive}
            displayType={'text'}
            thousandSeparator={true}
          />
        </span>
      )}
      {setEnterKeyForNameEditor || setEnterKeyForPurchaseEditor
        ? setFilterNameEditor()
        : ''}
      {mainDoneList.length > 0 ||
        mainfailList.length > 0 ||
        spin ||
        errorMessages.length > 0
        ? sweetMessage
        : ''}

      {sweetAlert}

      {show ? (
        <Alert
          variant="danger"
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading>
            <Icon>error_outline</Icon>You got an error!
          </Alert.Heading>
          <p>
            Buying: Error on bid delete, after 404 or 500 response
          </p>
        </Alert>
      ) : (
        ''
      )}

      <div className="card buying" style={{ position: 'relative' }}>
        {bulkUpdate && data.length > 0 && !loading && (
          <>
            <button className="bulkUpdate" onClick={fetchBulkUpdate}>
              Bulk Update
            </button>
            <span
              className="bulkProductsDelete"
              title="Bulk Products Delete"
            >
              <DeleteIcon
                onClick={() => fetchBulkUpdateWarning()}
                className="rowdel"
                title="Bulk Products Delete"
              />
            </span>
          </>
        )}
        <span
          className="diffIcon"
          title={diffShowing ? 'Show All Bids' : 'Exceeded Max Bid'}
        >
          <Icon onClick={() => handleDiff()}>error_outline</Icon>
        </span>
        <span className="globalBtn" title="Global Update">
          <Icon onClick={fetchGlobalUpdate}>language</Icon>
        </span>
        <span className="reload" title="Reload">
          <Icon onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>
        <div className="searchIcon">
          <Icon onClick={handleSearch} title="Search">
            search
          </Icon>
        </div>
        <Permission />
        <Grid rows={data} columns={columns} getRowId={getRowId}>
          {btn && !spin && (
            <>
              <button
                onClick={() => fetchNewBulk()}
                className="allBids"
              >
                Place all bids
              </button>
            </>
          )}
          <a
            href={EXPORT_API_URL}
            target="_blank"
            rel="noopener noreferrer"
            title="Export"
            className="export"
          >
            <img src={exportImg} alt="Loading" />
          </a>
          <SortingState
            sorting={sorting}
            onSortingChange={setSort}
            columnExtensions={sortingStateColumnExtensions}
          />
          <SearchState onValueChange={setSearchState} />

          <DataTypeProvider
            for={bidColumns}
            formatterComponent={BidFormatter}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={maxBidAmtColumns}
            formatterComponent={MaxBidFormatter}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={lowestAskColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={highestBidColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <LastSaleTypeProvider for={lastSaleColumns} />
          <DataTypeProvider
            for={numberOfBidsColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={numberOfAsksColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={parentIdColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={departmentColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={nameColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />

          <DataTypeProvider
            for={salesLast72HoursColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={brandColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={categoryColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={sizeColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={styleColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={colorColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={statusColumns}
            editorComponent={StatusEditor}
          />
          <DataTypeProvider
            for={autoBidActiveColumns}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={modelingAutoBidActiveColumns}
            availableFilterOperations={dynamicBidFilterOperations}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={retailPriceColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={releaseYearColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={updatedOnColumns}
            editorComponent={BidEditor}
          />

          <DataTypeProvider
            for={idColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />

          <DataTypeProvider
            for={autoSyncColumns}
            formatterComponent={AutoSyncFormatter}
            editorComponent={AutoSyncEditor}
            availableFilterOperations={dateFilterOperations}
          />

          <FilteringState
            onFiltersChange={getFilters}
            columnExtensions={filteringStateColumnExtensions}
          />

          <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            onCurrentPageChange={setNowPage} // to change current page
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <DragDropProvider />
          <GroupingState
            grouping={grouping}
            columnExtensions={groupingStateColumnExtensions} // to control grouping with some fields
            onGroupingChange={changeGrouping} // to change grouping fields
            expandedGroups={expandedGroups} // to expand/collapse the groups selected
            onExpandedGroupsChange={setExpandedGroups} // to handle grouped rows to toggle
          />
          <CustomGrouping
            getChildGroups={getChildGroups}
            grouping={tempGrouping}
            expandedGroups={tempExpandedGroups}
          />
          <ImageTypeProvider for={imageColumns} />
          <StatusTypeProvider for={statusColumns} />
          <AutoBidActiveProvider for={autoBidActiveColumns} />
          <ModelingAutoBidActiveProvider
            for={modelingAutoBidActiveColumns}
          />
          <EditingState onCommitChanges={commitChanges} />
          <VirtualTable
            messages={data.length === 0 && tableMessages}
          />
          <TableHeaderRow
            showSortingControls // to control the sorting in each fields
            className="group"
            showGroupingControls // to disable grouping
          />
          <TableFilterRow
            showFilterSelector
            iconComponent={FilterIcon}
            messages={filterMessages}
          />
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />
          <TableEditColumn
            showDeleteCommand
            messages={editColumnMessages}
          />

          <TableGroupRow />
          <Toolbar />

          <TableInlineCellEditing />

          <ColumnChooser />
          <GroupingPanel
            showSortingControls // to control the sorting for grouped field
            className="group"
            showGroupingControls // to disable grouping
          />
          <SearchPanel />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </>
  );
};
